import React from "react"
import PropTypes from "prop-types"
import { Row, Col, Button } from "antd"

import "./FeaturedNews.less"
import { Link } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"

function FeaturedNews(props) {
  const { t } = useI18next()
  return (
    <div className="featured-news" style={{ marginBottom: 40 }}>
      <Row gutter={40} align="middle" style={{ margin: "0 -15px" }}>
        <Col
          span={24}
          md={{ span: 12, order: 1 }}
          style={{ padding: "0 15px" }}
        >
          <img src={props.image} alt={props.title} width="100%" />
        </Col>
        <Col span={24} md={12} style={{ padding: "0 15px" }}>
          {props.category || props.date ? (
            <p style={{ marginBottom: 0, fontWeight: "600" }}>
              <span className="category">{props.category}</span> | {props.date}
            </p>
          ) : null}
          <h3 className="card-title">{props.title}</h3>
          <div
            style={{
              maxHeight: 130,
              display: "-webkit-box",
              WebkitLineClamp: 5,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
            dangerouslySetInnerHTML={{ __html: props.excerpt }}
          />
          <Button type="link">
            <Link to={props.url}>{t("Selengkapnya")}</Link>
          </Button>
        </Col>
      </Row>
    </div>
  )
}

FeaturedNews.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  excerpt: PropTypes.string,
  date: PropTypes.string,
  category: PropTypes.string,
  url: PropTypes.string,
}

FeaturedNews.defaultProps = {
  url: "/informasi/berita/single",
}

export default FeaturedNews
