import React from "react"
import PropTypes from "prop-types"
import { Button, Card } from "antd"
import { Link } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"

import "./CardNews.less"

const CardNews = ({ image, date, category, title, url }) => {
  const { t } = useI18next()

  return (
    <Card
      bordered={false}
      className="card-news"
      cover={<img alt={title} src={image} />}
    >
      <div className="card-meta">
        {category || date ? (
          <p style={{ marginBottom: 0, fontWeight: "600" }}>
            <span className="category">{category}</span> | {date}
          </p>
        ) : null}
        <h3 className="card-title">{title}</h3>
        <Button type="link">
          <Link to={`/informasi/berita/${url}`}>{t("Selengkapnya")}</Link>
        </Button>
      </div>
    </Card>
  )
}

CardNews.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  date: PropTypes.string,
  category: PropTypes.string,
  url: PropTypes.string,
}

export default CardNews
