import React, { useState } from "react"
import { Form, Input, Typography, Button, Modal } from "antd"
import axios from "axios"
import _get from "lodash/get"
import "./Newsletter.less"
import { useI18next } from "gatsby-plugin-react-i18next"

function Newsletter() {
  const [form] = Form.useForm()
  const { t } = useI18next()
  const [state, setState] = useState({
    visible: false,
    loading: false,
  })
  const onSubmit = ({ email }) => {
    setState({ visible: false, loading: true })
    axios
      .post(process.env.GATSBY_API_URL + "/frontend/newsletter", {
        email,
      })
      .then(res => {
        if (_get(res, "data.errors", []).length > 0) {
          setState({ visible: false, loading: false })
          form.setFields([
            {
              name: "email",
              errors: res.data.errors,
            },
          ])
          return
        }
        form.resetFields()
        setState({ visible: true, loading: false })
      })
      .catch(err => {
        form.setFields([
          {
            name: "email",
            errors: JSON.stringify(err.data),
          },
        ])
        form.resetFields()
        setState({ visible: false, loading: false })
      })
  }
  return (
    <div className="newsletter">
      <div className="newsletter-inside">
        <h3>{t("Berlangganan Newsletter Sekarang!")}</h3>
        <p>
          {t(
            "Nikmati berbagai konten menarik dan pengumuman terbaru dari LPDP langsung di inbox kamu."
          )}
        </p>
        <Form onFinish={onSubmit} form={form} layout="inline">
          <Form.Item
            className="input-wrapper"
            name={"email"}
            rules={[{ required: true, type: "email" }]}
          >
            <Input placeholder={t("Email Anda")} />
          </Form.Item>
          <Form.Item>
            <Button loading={state.loading} htmlType="submit">
              {t("Kirim")}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Modal
        visible={state.visible}
        footer={null}
        onCancel={() => setState({ loading: false, visible: false })}
      >
        <Typography.Title className="text-center">
          Email Anda Berhasil Terdaftar
        </Typography.Title>
        <p className="text-center">
          Nantikan update menarik seputar berita dan informasi terbaru dari LPDP
          langsung dari inbox Anda.
        </p>
      </Modal>
    </div>
  )
}

export default Newsletter
