import React from "react"
import { Link, graphql } from "gatsby"
import Seo from "../../components/seo"
import Layout from "../../app/layout"

import { Row, Col, Breadcrumb } from "antd"

import Container from "../../components/Container"
import CardNews from "../../components/CardNews"
import { ChevronRight } from "../../components/Icon"
import Newsletter from "../../components/Newsletter"
import FeaturedNews from "../../components/FeaturedNews/FeaturedNews"
import { useI18next } from "gatsby-plugin-react-i18next"

function KisahInspiratif({
  data: {
    granteeInspirationStories: data = {
      page_setting: {},
      highlighted_news: {},
    },
    allNews: { nodes: news = [] },
  },
}) {
  const { t } = useI18next()
  let rispro = news.reduce((acc, val) => {
    if (val.highlighted_news.news_category === "Risprostory") {
      acc = [...acc, val.highlighted_news]
    }
    acc = [...acc, ...val.item.filter(d => d.news_category === "Risprostory")]

    return acc
  }, [])

  if (rispro.length > 2) {
    rispro.splice(2, 0, { component: <Newsletter /> })
  } else {
    rispro = [...rispro, { component: <Newsletter /> }]
  }

  return (
    <Layout>
      <Seo title={data.page_setting.title} />

      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Breadcrumb.Item>
            <Link to="/">{t("Beranda")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{data.page_setting.title}</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <div className="section text-medium">
        <Container>
          <Row gutter={40} style={{ margin: "0 -15px" }}>
            <Col span={24} md={12} style={{ padding: "0 15px" }}>
              <h1 className="section-title">{data.page_setting.title}</h1>
            </Col>
            <Col span={24} md={12} style={{ padding: "0 15px" }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.page_setting.description,
                }}
              />
            </Col>
          </Row>
          <hr style={{ marginTop: 24, marginBottom: 40 }} />
          <FeaturedNews
            image={data.highlighted_news.image}
            title={data.highlighted_news.title}
            excerpt={data.highlighted_news.content}
            url={"/informasi/berita/" + data.highlighted_news.slug}
          />

          <Row gutter={30} style={{ marginTop: 24 }}>
            {rispro.map(function (item, i) {
              if (item.component) {
                return (
                  <Col span={24} md={8} key={i}>
                    <Newsletter />
                  </Col>
                )
              } else {
                return (
                  <Col span={24} md={8} key={i}>
                    <CardNews
                      title={item.title}
                      image={item.image}
                      url={"/informasi/berita/" + item.slug}
                    />
                  </Col>
                )
              }
            })}
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default KisahInspiratif

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    granteeInspirationStories(lang: { eq: $language }) {
      page_setting {
        description
        slug
        title
      }
      highlighted_news {
        content
        image
        is_highlight
        news_category
        photographer_name
        publish
        slug
        title
        updated_at
        writer_name
      }
    }

    allNews(filter: { lang: { eq: $language } }) {
      nodes {
        highlighted_news {
          news_category
          title
          slug
          image
        }
        item {
          news_category
          image
          title
          slug
        }
      }
    }
  }
`
